<template>
  <div class="gratitude" v-if="orderId">
    <p class="gratitude--title">
      <span>Заказ оформлен!</span>
      <span>После оплаты заказ будет готовиться к отправлению!</span>
      <span class="gratitude--title-red">
        ВНИМАНИЕ! Номер вашего заказа - {{ orderId }}
      </span>
      <span class="gratitude--title-red"> Сумма к оплате ИТОГО: {{ total }} р </span>
    </p>
    <ul class="gratitude--list">
      Оплата принимается переводом на карту банка или электронный кошелёк:
      <li class="gratitude--item">СБЕРБАНК: 4817 7601 7829 9008 (СБП +79081717444)</li>
      <li class="gratitude--item">Альфа-банк: 4584 4328 2295 8733 (СБП +79081717444)</li>
      <li class="gratitude--item">Qiwi кошелёк +79081717444</li>
      <li class="gratitude--item">ЮMoney кошелёк +79081717444</li>

      <li class="gratitude--item">
        При оплате укажите в комментарии номер Вашего заказа.
      </li>
      <li class="gratitude--item">
        Система автоматически присвоит заказу статус «Оплачен». Заказ будет сформирован и отправлен.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Gratitude',
  props: {
    orderId: {
      type: Number,
      required: false
    },
    total: {
      type: Number,
      required: false
    }
  }
}
</script>

<style scoped lang='scss'>

.gratitude {

  font-family: 'Montserrat', sans-serif;
  margin-left: 40px;

  &--title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    line-height: 46px;
    margin-bottom: 40px;

    &-red {
      color: var(--main-red);
    }
  }

  &--list {
    line-height: 30px;
    font-weight: 300;
  }
}

</style>